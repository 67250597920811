

export let androidJsGlobalName = "android"

export let android = window[androidJsGlobalName];
export const global = window.global || (window.global=window);
export function getWebViewJsId(){
    return android ? android.webViewJsId() : "-1";
}
const listeningMap = {}
init();
function init(){
    if(!android)return;
    global.onCallJsEvent = function (event){
        console.log("ooooooooooooooo global event", JSON.stringify(event));
        if(!event)return;
        try {
            for (const [index, value] of Object.entries(listeningMap)) {
                if(value.callbackEventType != event.type)continue;
                value.fun(event.data);
                if(value.once){
                    delete listeningMap[index];
                    // webViewJsListen时传入了once，所以java端自己就卸载侦听了
                    // webViewJsRemoveListen(value.target, index)
                }
            }
            return "ok"
        }catch (e){
            console.log('global on call js event error', e);
        }
    }
}
export function webViewJsListen(target, type, callbackEventType, once, fun){
    if(!android)return -1;
    // fun listen(target: String, type: String, callbackEventType:String, once: Boolean):String{
    const index = android?.listen(target, type, callbackEventType, once)
    if(!index)return -1;
    listeningMap[index] = {once, fun, target, type, callbackEventType}
    return index;
}

export function webViewJsRemoveListen(target, index){
    if(!android)return;
    return android.removeListen(target, index)
}
